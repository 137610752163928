.slider-container {
  
    width: 50vw;
    height: auto;
    margin: auto;
    overflow: hidden;
    margin-top: 4rem;
}

.slider {
    display: flex;
    width: 50vw;
    height: auto;
    margin: auto;
    /* overflow: hidden; */
}

.slider .slide-track {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    animation: scroll 100s linear infinite;
    -webkit-animation: scroll 100s linear infinite;
    width: calc(200px * 14);
}

.slider .slide {
    width: 200px;
    margin-left: 2rem;
    cursor: pointer;
}

.slider .slide img {
    width: 100%;
}

@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(calc(-200px * 7));
        transform: translateX(calc(-200px * 7));
    }
}

@media screen and (max-width: 960px) {
    .slider-container {
        width: 90vw;
        height: auto;
        margin: auto;
        margin-top: 4rem;
      }
    
      .slider {
        width: auto;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    
      .slide {
        width: calc(50% - 10px);
        margin: 5px;
        cursor: pointer;
        text-align: center;
      }
    
      .slide img {
        width: 100%;
      }
    
      .slide-track {
        animation: none;
      }
}