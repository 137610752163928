#contact {
    margin-top: 5rem;

}

.container.contact__container {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
    /* margin-top: 2rem; */
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    color: white;
    background: #3c787e;
    /* box-shadow: 0 0 32px 8px #90d5c6; */
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    /* border: 1px solid transparent; */
    transition: var(--transition);
}

.contact__option:hover {
    transform: scale(1.01);
	transition: all 0.2s ease-out;
    
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: inline-block;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/*/////////////FORM/////////////*/

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white)
}
input::placeholder,
textarea::placeholder {
    
    color: var(--color-white)
}


@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}