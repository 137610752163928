.title {
    font-size : "3rem"
}

@media screen and (max-width: 960px) {
   .title {
    font-size: 2rem;
    margin-top: 2rem;
   }
    
}